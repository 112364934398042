<template>
  <div>
    <div style="max-width: 400px; margin: auto;">
      <h2 class="my-4">담당자 로그인</h2>
      <div class="row">
        <div class="col">
          <label class="text-secondary" for="id">ID</label>
          <input class="form-control" id="id" v-model="id" />
        </div>
      </div>
      <div class="row my-4">
        <div class="col">
          <label class="text-secondary" for="pw">PW</label>
          <input type="password" class="form-control" id="pw" v-model="pw" />
        </div>
      </div>
      <div v-if="fail" class="row">
        <div class="col">
          <div v-if="fail=='requireId'" class="alert alert-danger">ID를 입력하세요.</div>
          <div v-if="fail=='requirePw'" class="alert alert-danger">PW를 입력하세요.</div>
          <div v-if="fail=='idpw'" class="alert alert-danger">ID 또는 PW를 확인하세요.</div>
        </div>
      </div>
      <div class="row">
        <div class="col text-center">
          <button type="submit" class="btn btn-primary" @click="loginClick">로그인</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  setup() {
    let fail = ref(null);
    let id = ref('');
    let pw = ref('');

    let loginClick = () => (async () => {
      try{
        if(!id.value) return fail.value = 'requireId';
        if(!pw.value) return fail.value = 'requirePw';
        let res = (await backend({
          action: 'login',
          id: id.value,
          pw: pw.value
        }));
        if(res.isOk){
          location.href = '/' + (res.isAdmin ? 'manager' : 'coupon') + '-1.html';
        }else{
          fail.value = 'idpw';
        }
      }catch(e){
        console.log(e);
      }
    })();

    return { fail, id, pw, loginClick };
  }
}
</script>
